export const constants = {
	/** Products action types */
	PRODUCT_NEW_ADD_REQUEST: 'PRODUCT_NEW_ADD_REQUEST',
	PRODUCT_NEW_ADD_SUCCESS: 'PRODUCT_NEW_ADD_SUCCESS',
	PRODUCT_NEW_ADD_FAILED: 'PRODUCT_NEW_ADD_FAILED',
	PRODUCT_NEW_ADD_RESET: 'PRODUCT_NEW_ADD_RESET',

	/** Products action types */
	PRODUCT_LIST_REQUEST: 'PRODUCT_LIST_REQUEST',
	PRODUCT_LIST_SUCCESS: 'PRODUCT_LIST_SUCCESS',
	PRODUCT_LIST_FAILED: 'PRODUCT_LIST_FAILED',

	/** Product Edit action types */
	PRODUCT_EDIT_REQUEST: 'USER_EDIT_REQUEST',
	PRODUCT_EDIT_SUCCESS: 'USER_EDIT_SUCCESS',
	PRODUCT_EDIT_FAIL: 'USER_EDIT_FAIL',
	PRODUCT_EDIT_RESET: 'USER_EDIT_RESET',

	/** Product Detail action types */
	SINGLE_PRODUCT_REQUEST: 'SINGLE_PRODUCT_REQUEST',
	SINGLE_PRODUCT_SUCCESS: 'SINGLE_PRODUCT_SUCCESS',
	SINGLE_PRODUCT_FAILED: 'SINGLE_PRODUCT_FAILED',
	SINGLE_PRODUCT_RESET: 'SINGLE_PRODUCT_RESET',

	/** Delete a single Product action types */
	DELETE_SINGLE_PRODUCT_REQUEST: 'DELETE_SINGLE_PRODUCT_REQUEST',
	DELETE_SINGLE_PRODUCT_SUCCESS: 'DELETE_SINGLE_PRODUCT_SUCCESS',
	DELETE_SINGLE_PRODUCT_FAILED: 'DELETE_SINGLE_PRODUCT_FAILED',

	/** Product Reviews action types */
	PRODUCT_REVIEW_NEW_ADD_REQUEST: 'PRODUCT_NEW_ADD_REQUEST',
	PRODUCT_REVIEW_NEW_ADD_SUCCESS: 'PRODUCT_NEW_ADD_SUCCESS',
	PRODUCT_REVIEW_NEW_ADD_FAILED: 'PRODUCT_NEW_ADD_FAILED',
	PRODUCT_REVIEW_NEW_ADD_RESET: 'PRODUCT_NEW_ADD_RESET',

	/** Cart action types */
	CART_ADD_ITEM: 'CART_ADD_ITEM',
	CART_REMOVE_ITEM: 'CART_REMOVE_ITEM',
	CART_ITEMS_RESET: 'CART_ITEMS_RESET',

	/** REGISTER */
	USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
	USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
	USER_REGISTER_FAILED: 'USER_REGISTER_FAILED',
	USER_REGISTER_RESET: 'USER_REGISTER_RESET',
	REMOVE_USER_REGISTER_ERRORS: 'REMOVE_USER_REGISTER_ERRORS',

	/** LOGIN */
	USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST ',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',
	USER_LOGIN_RESET: 'USER_LOGIN_RESET',
	USER_LOGOUT: 'USER_LOGOUT',

	/** AS GUEST */
	USER_AS_GUEST_REQUEST: 'USER_AS_GUEST_REQUEST ',
	USER_AS_GUEST_SUCCESS: 'USER_AS_GUEST_SUCCESS',
	USER_AS_GUEST_FAILED: 'USER_AS_GUEST_FAILED',
	USER_AS_GUEST_RESET: 'USER_AS_GUEST_RESET',

	/** USER LIST */
	USER_LIST_REQUEST: 'USER_LIST_REQUEST ',
	USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
	USER_LIST_FAILED: 'USER_LIST_FAILED',
	USER_LIST_RESET: 'USER_LIST_RESET',

	/** USER DELETE */
	USER_DELETE_REQUEST: 'USER_DELETE_REQUEST ',
	USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
	USER_DELETE_FAILED: 'USER_DELETE_FAILED',
	USER_DELETE_RESET: 'USER_DELETE_RESET',

	/** PROFLE UPDATE */
	USER_PROFILE_UPDATE_REQUEST: 'USER_PROFILE_UPDATE_REQUEST',
	USER_PROFILE_UPDATE_SUCCESS: 'USER_PROFILE_UPDATE_SUCCESS',
	USER_PROFILE_UPDATE_FAILED: 'USER_PROFILE_UPDATE_FAILED',

	/** GET USER DETAILS */
	USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
	USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
	USER_DETAILS_FAIL: 'USER_DETAILS_FAIL',
	USER_DETAILS_RESET: 'USER_DETAILS_RESET',

	/** SAVE USER SHIPPPING ADDRESS */
	CART_SAVE_USER_SHIPPING_ADDRESS: 'CART_SAVE_USER_SHIPPING_ADDRESS',

	/** SAVE USER PAYMENT METHOD */
	CART_SAVE_USER_PAYMENT_METHOD: 'CART_SAVE_USER_PAYMENT_METHOD',

	/** SAVE ORDER */
	ORDER_CREATE_REQUEST: 'ORDER_CREATE_REQUEST',
	ORDER_CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
	ORDER_CREATE_FAIL: 'ORDER_CREATE_FAIL',
	ORDER_CREATE_RESET: 'ORDER_CREATE_RESET',

	/** GET ORDER LIST */
	ORDER_LIST_REQUEST: 'ORDER_LIST_REQUEST',
	ORDER_LIST_SUCCESS: 'ORDER_LIST_SUCCESS',
	ORDER_LIST_FAIL: 'ORDER_LIST_FAIL',
	ORDER_LIST_RESET: 'ORDER_LIST_RESET',

	/** SAVE ORDER */
	ORDER_CANCEL_REQUEST: 'ORDER_CANCEL_REQUEST',
	ORDER_CANCEL_SUCCESS: 'ORDER_CANCEL_SUCCESS',
	ORDER_CANCEL_FAIL: 'ORDER_CANCEL_FAIL',
	ORDER_CANCEL_RESET: 'ORDER_CANCEL_RESET',

	/** GET ORDER DETAILS BY ID */
	ORDER_DETAILS_BY_ID_REQUEST: 'ORDER_DETAILS_BY_ID_REQUEST',
	ORDER_DETAILS_BY_ID_SUCCESS: 'ORDER_DETAILS_BY_ID_SUCCESS',
	ORDER_DETAILS_BY_ID_FAIL: 'ORDER_DETAILS_BY_ID_FAIL',

	/** GET USER'S ORDERs */
	MY_ORDERS_REQUEST: 'MY_ORDERS_REQUEST',
	MY_ORDERS_SUCCESS: 'MY_ORDERS_SUCCESS',
	MY_ORDERS_FAIL: 'MY_ORDERS_FAIL',
	MY_ORDERS_RESET: 'MY_ORDERS_RESET',

	/** PAY ORDER */
	ORDER_PAY_REQUEST: 'ORDER_PAY_REQUEST',
	ORDER_PAY_SUCCESS: 'ORDER_PAY_SUCCESS',
	ORDER_PAY_FAIL: 'ORDER_PAY_FAIL',
	ORDER_PAY_RESET: 'ORDER_PAY_RESET',

	/** ADMIN PAY ORDER */
	ORDER_PAY_ADMIN_REQUEST: 'ORDER_PAY_ADMIN_REQUEST',
	ORDER_PAY_ADMIN_SUCCESS: 'ORDER_PAY_ADMIN_SUCCESS',
	ORDER_PAY_ADMIN_FAIL: 'ORDER_PAY_ADMIN_FAIL',
	ORDER_PAY_ADMIN_RESET: 'ORDER_PAY_ADMIN_RESET',

	/** DELIVER ORDER */
	ORDER_DELIVER_REQUEST: 'ORDER_DELIVER_REQUEST',
	ORDER_DELIVER_SUCCESS: 'ORDER_DELIVER_SUCCESS',
	ORDER_DELIVER_FAIL: 'ORDER_DELIVER_FAIL',
	ORDER_DELIVER_RESET: 'ORDER_DELIVER_RESET',

	/** NOT DELIVER ORDER */
	ORDER_NOT_DELIVER_REQUEST: 'ORDER_NOT_DELIVER_REQUEST',
	ORDER_NOT_DELIVER_SUCCESS: 'ORDER_NOT_DELIVER_SUCCESS',
	ORDER_NOT_DELIVER_FAIL: 'ORDER_NOT_DELIVER_FAIL',
	ORDER_NOT_DELIVER_RESET: 'ORDER_NOT_DELIVER_RESET',

	/** get paypal-client-id */
	PAYPAL_CLIENT_ID_REQUEST: 'PAYPAL_CLIENT_ID_REQUEST',
	PAYPAL_CLIENT_ID_SUCCESS: 'PAYPAL_CLIENT_ID_SUCCESS',
	PAYPAL_CLIENT_ID_FAIL: 'PAYPAL_CLIENT_ID_FAIL',

	/** EDIT USER */
	USER_EDIT_REQUEST: 'USER_EDIT_REQUEST',
	USER_EDIT_SUCCESS: 'USER_EDIT_SUCCESS',
	USER_EDIT_FAIL: 'USER_EDIT_FAIL',
	USER_EDIT_RESET: 'USER_EDIT_RESET'
}
