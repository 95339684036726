import React from 'react'
import PropTypes from 'prop-types'

/** i18n */
import { useTranslation } from 'react-i18next'

const Ratings = ({ rating, numReviews, color }) => {
	const { t } = useTranslation()

	let nums = [1, 2, 3, 4, 5]
	const ratingValue = rating?.$numberDecimal || rating ? rating?.$numberDecimal || rating : 0

	return (
		<div>
			{nums.map((num) => (
				<i
					key={num}
					className={ratingValue >= num ? 'fas fa-star pr-1' : ratingValue >= num - 0.5 ? 'fas fa-star-half-alt pr-1' : 'far fa-star pr-1'}
					style={{ color: color }}
				></i>
			))}
			<span className='mr-2'>{ratingValue} / 5</span>
			<span className='mr-2'>
				{numReviews && (
					<span>
						({numReviews} {t('reviews')})
					</span>
				)}
			</span>
		</div>
	)
}

Ratings.defaultProp = {
	color: 'gold'
}

Ratings.propTypes = {
	rating: PropTypes.object,
	numReviews: PropTypes.number,
	color: PropTypes.string
}

export default Ratings
