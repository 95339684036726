import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { constants } from '../constants/constant.js'

/** bootstrap */
import { Row, Col, ListGroup, Card, Button, Form, Container, Modal } from 'react-bootstrap'

/** Tostify */
// import { ToastContainer, toast } from 'react-toastify';

/** actions */
import { addNewProductReview, getSingleProduct, getProducts, deleteSingleProduct } from '../actions/productActions.js'
import { addProductToCart, removeProductFromCart } from '../actions/cartActions.js'

/** Components */
import Ratings from '../components/Ratings'
import Loader from '../components/Loader'
import Message from '../components/Message'
import ImgSlider from '../components/ImgSlider.js'

/** Custom Hooks */
import useBreakpoint from '../customHooks/useBreakpoint'

/** i18n */
import { useTranslation } from 'react-i18next'

const ProductScreen = ({ history, match }) => {
	const { t, i18n } = useTranslation()
	const currentLanguage = i18n.language

	const { width } = useBreakpoint()

	const [qty, setQty] = useState(1)
	const [rating, setRating] = useState(0)
	const [comment, setComment] = useState('')
	const [mockCommentName, setMockCommentName] = useState(null)
	const { deletedProduct, loading: loadingDeleted, error: errorDeleted } = useSelector((state) => state.productDelete)

	const dispatch = useDispatch()
	const { loading, error, product } = useSelector((state) => state.product)
	const { loading: loadingReview, error: errorReview, productReviewAdded } = useSelector((state) => state.productReviewNewAdd)
	const { cartItems } = useSelector((state) => state.cart)
	const { userInfo: user } = useSelector((state) => state.userLogin)

	const initial = cartItems.filter((item) => item._id === match.params.id).length ? true : false
	const [isInCart, setIsInCart] = useState(initial)

	/** If state is in Basic Version */
	// const { loading, error, product } = useSelector(state => state)

	useEffect(() => {
		dispatch(getSingleProduct(match.params.id))
	}, [dispatch, match.params.id, productReviewAdded])

	useEffect(() => {
		if (user?.isAdmin && deletedProduct) {
			dispatch(getProducts())
			dispatch({ type: constants.DELETE_SINGLE_PRODUCT_RESET })
			history.push(`/`)
		}
	}, [dispatch, user, deletedProduct, history])

	function addToCartHandler() {
		// history.push(`/cart/${match.params.id}?qty=${qty}`);
		dispatch(addProductToCart(match.params.id, qty))
		setIsInCart(true)
	}

	const removeFromCartHandler = () => {
		dispatch(removeProductFromCart(match.params.id))
		setIsInCart(false)
	}

	const addToReviewHandler = (e) => {
		e.preventDefault()

		try {
			dispatch(addNewProductReview(match.params, { rating, comment, mockCommentName }))
			setRating(0)
			setComment('')
		} catch (err) {
			// toast.error(err?.data?.message || err.error);
			console.log('error: ', err?.data?.message || err.error)
		}
	}

	const [show, setShow] = useState(false)
	const [productForDelete, setProductForDelete] = useState(null)

	const deleteHandlerShow = (user) => {
		setProductForDelete(user)
		setShow(true)
	}

	const handleConfirm = (confirm) => {
		if (confirm) {
			dispatch(deleteSingleProduct(productForDelete._id))
		}
		setShow(false)
	}

	const currency = (amount) =>
		new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR'
		}).format(amount)
	const capitilaseFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1)

	return (
		<Container>
			{loading || loadingDeleted ? (
				<Loader />
			) : error || errorDeleted ? (
				<Message variant='danger'>{error || errorDeleted}</Message>
			) : (
				<>
					<Link to='/' className='btn btn-dark'>
						{t('go-back')}
					</Link>
					<Row className='my-3'>
						<Col
							md={12}
							lg={5}
							className='d-flex no-gutter justify-content-center align-items-center my-3'
							style={width < 576 ? { height: '200px' } : { height: '400px' }}
						>
							{/* <Image src={product?.image} fluid className="w-100 h-100" style={{ 'objectFit': 'cover' }} /> */}
							{product && (
								<ImgSlider
									productId={product._id}
									images={product.image}
									style={{
										width: '100%',
										height: width < 576 ? '200px' : '400px'
									}}
								/>
							)}
						</Col>

						<Col md={7} lg={4}>
							<ListGroup variant='flush'>
								<ListGroup.Item className='px-0'>
									<h3>{product?.name}</h3>
								</ListGroup.Item>

								<ListGroup.Item className='px-0'>
									<Ratings rating={product?.rating} numReviews={product?.numReviews} color='gold'></Ratings>
								</ListGroup.Item>

								<ListGroup.Item className='px-0'>
									<h1>
										{t('price')}: {currency(product?.price)}
									</h1>
								</ListGroup.Item>

								<ListGroup.Item className='px-0'>
									{t('description')}: {product?.description}
								</ListGroup.Item>
							</ListGroup>
						</Col>

						<Col md={5} lg={3} className='my-3'>
							<Card>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<Row>
											<Col>{t('price')}: </Col>
											<Col>
												<strong>{currency(product?.price)}</strong>
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<Row>
											<Col>Status: </Col>
											<Col>{product?.countInStock > 0 ? 'In Stock' : 'Out of Stock'}</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<Row>
											<Col className='d-flex align-items-center'>{t('quantity')}: </Col>
											<Col>
												{product?.countInStock > 0 && (
													<Form.Control as='select' value={qty} onChange={(e) => setQty(e.target.value)}>
														{[...Array(product.countInStock).keys()].map((num) => (
															<option key={num}>{num + 1}</option>
														))}
													</Form.Control>
												)}
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<Row>
											{isInCart ? (
												<Button
													type='button'
													className='btn btn-block'
													disabled={product?.countInStock <= 0 ? true : false}
													onClick={removeFromCartHandler}
												>
													<i className='fas fa-times mr-2'></i>
													{t('remove-from-cart')}
												</Button>
											) : (
												<Button
													type='button'
													className='btn btn-block'
													disabled={product?.countInStock <= 0 ? true : false}
													onClick={addToCartHandler}
												>
													<i className='fas fa-plus mr-2'></i>
													{t('add-to-cart')}
												</Button>
											)}
										</Row>
										<Row>
											<Link to='/cart' className='btn btn-info btn-block mt-2'>
												<i className='fas fa-shopping-cart mr-2'></i>
												{t('go-to-cart')}
											</Link>
										</Row>
										{user?.isAdmin && (
											<>
												<Row>
													<Link to={`/admin/productedit/${match.params.id}/edit`} className='btn btn-warning btn-block mt-2'>
														<i className='fas fa-cut mr-2'></i>
														{t('edit-product')}
													</Link>
												</Row>

												<Row>
													<Button
														className='btn btn-warning btn-block mt-2'
														variant='danger'
														onClick={() => deleteHandlerShow(product)}
														data-toggle='confirmation'
													>
														<i className='fas fa-trash mr-2'></i>
														{t('delete-product')}
													</Button>
												</Row>
											</>
										)}
									</ListGroup.Item>
								</ListGroup>
							</Card>
						</Col>
					</Row>

					<Row className='review my-3'>
						<Col md={12} lg={9}>
							<h2>{t('write-a-review')}</h2>

							{loadingReview && <Loader />}

							{errorReview && <Message variant='danger'>{errorReview}</Message>}

							{user ? (
								<ListGroup variant='flush'>
									<ListGroup.Item className='p-0 mt-3'>
										<Form onSubmit={addToReviewHandler}>
											{user?.isAdmin && (
												<Form.Group controlId='mockCommentName'>
													<Form.Label>Fake Name (only for admins):</Form.Label>
													<Form.Control type='text' value={mockCommentName} onChange={(e) => setMockCommentName(e.target.value)}></Form.Control>
												</Form.Group>
											)}

											<Form.Group controlId='rating'>
												<Form.Label>{capitilaseFirst(t('rating'))}</Form.Label>
												<Form.Control as='select' value={rating} onChange={(e) => setRating(e.target.value)}>
													<option value=''>{t('select-rating')}</option>
													<option value='1'>1 - {t('bad')}</option>
													<option value='2'>2 - {t('regular')}</option>
													<option value='3'>3 - {t('good')}</option>
													<option value='4'>4 - {t('very-good')}</option>
													<option value='5'>5 - {t('excellent')}</option>
												</Form.Control>
											</Form.Group>

											<Form.Group controlId='comment'>
												<Form.Label>{capitilaseFirst(t('comment'))}</Form.Label>
												<Form.Control as='textarea' rows={3} value={comment} onChange={(e) => setComment(e.target.value)}></Form.Control>
											</Form.Group>

											<Button type='submit' className='btn btn-success float-right'>
												{t('submit')}
											</Button>
										</Form>
									</ListGroup.Item>
								</ListGroup>
							) : currentLanguage === 'en' ? (
								<Message>
									Please <Link to='/login'>Sign In</Link> to write a review.
								</Message>
							) : (
								<Message>
									Bitte <Link to='/login'>Anmelden</Link> um eine Bewertung zu schreiben.
								</Message>
							)}
						</Col>
					</Row>

					<Row className='review my-3'>
						<Col md={12} lg={9}>
							<h2>{capitilaseFirst(t('reviews'))}</h2>
							<ListGroup variant='flush'>
								<ListGroup.Item className='p-0 mt-3'>
									{product?.reviews.length === 0 && <Message variant='info'>{t('no-reviews')}</Message>}

									{product?.reviews.map((review) => (
										<ListGroup.Item key={review._id}>
											<Row>
												<Col>
													<strong>{review.name}</strong>
												</Col>
												<Col className='text-right'>{review.createdAt.substring(0, 10)}</Col>
											</Row>

											<Row>
												<Col>
													<Ratings rating={review.rating} color='gold'></Ratings>
												</Col>
											</Row>

											<Row>
												<Col className='mt-3'>{review.comment}</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup.Item>
							</ListGroup>
						</Col>
					</Row>

					<Modal show={show} size={'lg'} onHide={() => handleConfirm(false)}>
						<Modal.Body>
							<h3> {t('would-you-really-want-to-remove-the-product')}? </h3>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={() => handleConfirm(false)}>
								{t('no')}
							</Button>
							<Button variant='primary' onClick={() => handleConfirm(true)}>
								{t('yes')}
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</Container>
	)
}

export default ProductScreen
